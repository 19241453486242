import cropService from '../../services/cropService';
// import farmerService from '../../services/farmerService'
import businessUsersService from '../../services/businessUsersService';
export const namespaced = true;
export const state = {
	crops: [],
	filterBy: {
		name: '',
	},
};

export const mutations = {
	SET_CROPS(state, crops) {
		state.crops = crops;
	},
	ADD_CROP(state, crop) {
		console.log(crop);
		state.crops.push(crop);
	},
	SET_FILTER(state, filterBy) {
		state.filterBy = filterBy;
		console.log(state.filterBy);
	},
	UPDATE_CROP(state, crop) {
		const idx = state.crops.findIndex((c) => c._id === crop._id);
		state.crops.splice(idx, 1, crop);
	},
	DELETE_CROP(state, cropId) {
		const idx = state.crops.findIndex((c) => c._id === cropId);
		state.crops.splice(idx, 1);
	},
};

export const getters = {
	cropsForDisplay(state) {
		return state.crops.filter((crop) => crop.status === 'ACTIVE');
	},
};

export const actions = {
	addCrop({ commit }, crop) {
		return new Promise((resolve) => {
			cropService
				.addCrop(crop)
				.then((response) => {
					console.log(response.data);
					commit('ADD_CROP', response.data.data);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
	getCrops({ commit }) {
		return new Promise((resolve) => {
			cropService
				.getCrops()
				.then((response) => {
					console.log(response.data.data);
					commit('SET_CROPS', response.data.data);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
	getFarmerCropsById({ commit }, crops) {
		commit('SET_CROPS', crops);
	},

	getFarmerCrops() {
		return new Promise((resolve) => {
			cropService
				.getFarmerCrops()
				.then((response) => {
					console.log(response.data);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
	updateCrop({ commit }, crop) {
		return new Promise((resolve) => {
			cropService
				.updateCrop(crop)
				.then((response) => {
					console.log(response.data);
					commit('UPDATE_CROP', response.data.data);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error);
				});
		});
	},
	deleteCrop({ commit }, cropId) {
		return new Promise((resolve) => {
			businessUsersService
				.deleteCrop(cropId)
				.then((response) => {
					console.log(response.data);
					commit('DELETE_CROP', cropId);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error);
				});
		});
	},
};
