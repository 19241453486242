import axios from 'axios';
import store from '../store/store';

const envUrl = process.env.VUE_APP_URL;
const BASEURL = `${envUrl}crop/`;
const APPKEY = 'bxXnw3Wxp5V4GWVei5EgvXdMnKB9llOHIBV4';

export default {
	addCrop(crop) {
		let config = {
			method: 'post',
			url: `${BASEURL}createCrop`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: crop,
		};
		console.log(config);
		return axios(config);
	},
	getCrops() {
		let config = {
			method: 'get',
			url: `${BASEURL}getCrops`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	getFarmerCrops() {
		let config = {
			method: 'get',
			url: `${BASEURL}getFarmerCrops`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	updateCrop(crop) {
		let config = {
			method: 'put',
			url: `${BASEURL}updateCrop`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: crop,
		};
		console.log(config);
		return axios(config);
	},
};
